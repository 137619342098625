import React from "react";

import './Shop.css';
import exchangePlatforms from "../../data/exchange-platforms";
import GlassButton from "../../components/glass-button";
import GlassCard from "../../components/glass-card";
import { Element } from "react-scroll";
import Tilt from 'react-vanilla-tilt';

const Shop = () => {
    return(
        <Element className="shop-container" name="shop">
            {exchangePlatforms.map((platform, index) => {
                return(
                    <Tilt style={{}} key={index}>
                        <GlassCard extendClass="exchange-platform">
                            <img src={platform.img} alt="exchange-platform" />
                            <h2>Buy Bober on {platform.name}</h2>
                            <p className="platform-description">{platform.description}</p>
                            <GlassButton>
                                <a href={platform.url} target="_blank" rel="noreferrer">
                                    {platform.buttonContent}
                                    <img src="/assets/logo.png" alt="logo" />
                                </a>
                            </GlassButton>
                        </GlassCard>
                    </Tilt>
                )
            })}
        </Element>
    
    )
}

export default Shop;