import React from "react";

const GlassCard = ({children, extendClass}) => {
    return(
        <div className={`glass-card ${extendClass}`}>
            {children}
        </div>
    )
}

export default GlassCard;