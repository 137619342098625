import React from "react";
import './About.css';
import ImageFollow from "react-image-follow";
import GlassCard from "../../components/glass-card";
import { Element } from "react-scroll";

const About = () => {
    return(
        <Element className='about-container' name="about">
            <h1>Introducing <span className="glowing-text">Bobr on Base</span></h1>
            <div className="about-grid">
                <div className="grid-row">
                    <div className="description">
                        <h1>General Info</h1>
                        <div className="info">
                            <p>In the rapidly evolving world of cryptocurrency, a new contender is on the horizon, promising not just to be another digital asset but a cultural phenomenon as well. Bober, named after the industrious and adaptable beaver from Polish memes, represents more than just a novel financial tool; it is a bridge between the dynamic world of internet culture and the serious, often complex, domain of blockchain technology.</p>
                            <p>The genesis of Bober is as intriguing as its namesake. In Polish culture, the beaver, or "bober," is often associated with diligence, creativity, and resilience. These traits are not just emblematic of the animal itself but are also deeply ingrained in the ethos of Bober the cryptocurrency. Drawing from a rich tapestry of meme culture, Bober stands as a testament to the power of community and shared humor, aiming to integrate these elements into the fabric of its identity. This approach is not merely about adopting a name; it's about embodying the spirit of the beaver's ability to transform and innovate, making Bober a symbol of change and adaptability in the digital age.</p>
                        </div>
                    </div>
                    <ImageFollow src="/assets/bobr-bilder.png"/>
                </div>
                <Element className="grid-row" name="tokenomics">
                    <ImageFollow src="/assets/happy-beaver.png" className="big-screen-image"/>
                    <div className="description">
                        <h1>Tokenomics</h1>
                        <GlassCard extendClass="info-card">
                            <ul>
                                <li><span className="list-title">Name:</span>Bobr on Base</li>
                                <li><span className="list-title">Symbol:</span>Bober Kurwa</li>
                                <li><span className="list-title">Contract address:</span><br/>0xa9Mk2e8F7EbB99f83B603C55rOlDSf07AacCfd</li>
                                <li><span className="list-title">Supply:</span>420,690,000,000,000 Bober</li>
                                <li><span className="list-title">Initial Liquidity:</span>1 ETH</li>
                                <li><span className="list-title">Launch Price:</span>$0.000000000004915</li>
                                <li><span className="list-title">Launch Date:</span>15th May 2024</li>
                                <li><span className="list-title">Allocation:</span>100% LIQUIDITY POOL</li>
                                <li>0% TAXES - LP BURNT - CONTRACT RENOUNCED</li>
                                <li>No Blacklists</li>
                                <li>No free tokens</li>
                                <li>No Bullshit. It's that simple</li>
                            </ul>
                        </GlassCard>
                            
                    </div>
                    <ImageFollow src="/assets/happy-beaver.png" className="small-screen-image"/>
                </Element>
            </div>
        </Element>
    )
}

export default About;