import React from "react";

import './Home.css';

import GlassButton from "../../components/glass-button";
import CopyToClipboard from "react-copy-to-clipboard";
import { Tooltip } from "@mui/material";
import { Element, Link } from "react-scroll";
const Home = ({showKurwaGif, showKurwaImage}) => {
    const [isCopy, setIsCopy] = React.useState(false);

    const handleCopy = () => {
        setIsCopy(true);
        setTimeout(() => {
            setIsCopy(false);
        }, 1000);
    }

    return(  
        <Element className='hero' name="home">
          <div className='quote'>
            <div className='quote-title'>
              <h1>Bobr on Base</h1>
              <h2><span className='spinning glowing-text' onMouseEnter={showKurwaGif} onMouseLeave={showKurwaImage}>Spinning</span> to the Moon!</h2>
            </div>
            <div className='quote-description'>
              <p>Bober on Base is an emerging meme coin, designed for the crypto community with a focus on humor and online trends. Emphasizing fun and community engagement, it aims to unite enthusiasts in a lighthearted way.</p>
            </div>
            <a href=''>
                <Link to="shop" smooth={true}>
                  <GlassButton showKurwaGif={showKurwaGif} showKurwaImage={showKurwaImage}>Buy Bobr</GlassButton>
                </Link>
            </a> 
            <CopyToClipboard text="0xa9Mk2e8F7EbB99f83B603C55rOlDSf07AacCfd" onCopy={handleCopy} >
              <Tooltip title="Copy 0xa9Mk2e8F7EbB99f83B603C55rOlDSf07AacCfd" >
                <button className='copy-address glass-button'>0xfsdf...f07Cfd</button>
              </Tooltip>
            </CopyToClipboard>
            <h1 className={`${isCopy ? 'show-copy' : 'copy'}`}>Copied!</h1>
          </div>
          <div className='image-container'>
            <h1 className='bober-shouth'>Bobeeer!</h1>
            <img className='show kurwa-image' src='/assets/bober-no-background.png' />
            <img className='kurwa-gif' src='/assets/bober-kurwa.gif' />
          </div>
        </Element>
    )
}

export default Home;