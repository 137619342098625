const exchangePlatforms = [
    {
        name: 'Uniswap',
        description: 'Uniswap offers decentralized, Ethereum-based token swapping with automatic liquidity, appealing for its transparency and user control.',
        img: '/assets/uniswap.png',
        url: 'https://app.uniswap.org/',
        buttonContent: 'Buy',
    },
    {
        name: 'DexTools',
        description: `
        DexTools provides real-time data analysis for decentralized exchanges, trends, and market intelligence to make informed decisions in the DeFi space.`,
        img: '/assets/dextools.png',
        url: 'https://www.dextools.io/',
        buttonContent: 'See token',
    },
    // {
    //     name: 'Binance',
    //     description: 'Binance excels with high liquidity, a wide cryptocurrency selection, user-friendly interface, and diverse trading options, attracting both novice and experienced traders.',
    //     img: '/assets/binance.png',
    //     url: 'https://www.binance.com/'
    // },
    // {
    //     name: 'Bitfinex',
    //     description: 'Bitfinex offers advanced trading features, high liquidity in various cryptocurrencies and a strong security measures appealing to experienced traders and investors.',
    //     img: '/assets/bitfinex.png',
    //     url: 'https://www.bitfinex.com/'
    // },
    // {
    //     name: 'Bybit',
    //     description: 'Bybit is favored for its user-friendly platform, competitive trading fees, strong security, and a variety of derivatives products, attracting both new and experienced traders.',
    //     img: '/assets/bybit.png',
    //     url: 'https://www.bybit.com/'
    // },
    
    // {
    //     name: 'Apeswap',
    //     description: 'ApeSwap, a decentralized finance (DeFi) platform, features automated liquidity provision, yield farming, and staking, targeting users seeking passive income in the crypto space.',
    //     img: '/assets/apeswap.png',
    //     url: 'https://apeswap.finance/'
    // },
    // {
    //     name: 'Bithumb',
    //     description: `
    //     Bithumb, a leading South Korean cryptocurrency exchange, offers a wide range of digital assets, high liquidity, robust security features, and user-friendly services for traders globally.`,
    //     img: '/assets/bithumb.png',
    //     url: 'https://www.bithumb.com/'
    // },
    
]

export default exchangePlatforms;
