
import './App.css';
import Navbar from './components/navbar';
import Home from './pages/home/Home'
import Shop from './pages/shop/Shop'
import About from './pages/about/About'
import GlassCard from './components/glass-card';

function App() {
  function showKurwaGif(){
    document.querySelector('.kurwa-image').classList.remove('show')
    document.querySelector('.kurwa-gif').classList.add('show')
    document.querySelector('.bober-shouth').classList.add('show-shotout')
  }

  function showKurwaImage(){
    document.querySelector('.kurwa-image').classList.add('show')
    document.querySelector('.kurwa-gif').classList.remove('show')
    document.querySelector('.bober-shouth').classList.remove('show-shotout')
  }
  
  return (
    <div className="App">
      <div className='container'>
        <Navbar showKurwaGif={showKurwaGif} showKurwaImage={showKurwaImage}/>
        <Home showKurwaGif={showKurwaGif} showKurwaImage={showKurwaImage}/>
        <About />
        <Shop />
        <GlassCard extendClass='footer'>
          <p>THIS BOBR IS A MEMECOIN AND HAS NO VALUE. DON'T RISK MONEY YOU ARE AFRAID OF LOSING. THE PRICE MAY GO UP OR IT MAY GO DOWN. WE ARE NOT RESPONSIBLE FOR THE PRICE OF THE TOKEN.</p>
        </GlassCard>
      </div>
    </div>
  );
}

export default App;
