import React from "react";
import GlassButton from "./glass-button";
import { Link } from "react-scroll";
const Navbar = ({showKurwaGif, showKurwaImage}) => {
    return (
        <div className='navbar'>
            <a href='/'className='logo'>
                <img src='/assets/logo.png' />
                <h3>BobrOnBase</h3>
                <img className="base-logo" src='/assets/base-logo.png' />
            </a>
            
            <nav>
                <ul className='socials'>
                    <li><a href=""><Link to="home" smooth={true}>Home</Link></a></li>
                    <li><a href=""><Link to="about" smooth={true}>About</Link></a></li>
                    <li><a href=""><Link to="tokenomics" smooth={true}>Tokenomics</Link></a></li>
                </ul>
            </nav>

            <a href=''>
                <Link to="shop" smooth={true}>
                    <GlassButton showKurwaGif={showKurwaGif} showKurwaImage={showKurwaImage} extendClass='glowing-text buy-bobr-nav'>Buy Bobr</GlassButton>
                </Link>
            </a>        
         
        </div>
    );
}

export default Navbar;